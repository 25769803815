import React, {useEffect} from "react";
import { useTranslation, Trans } from "react-i18next";
import ImgOthers from "../imgs/others.png";
import Tabs from "./ui/tabs";
import AOS from 'aos';
import 'aos/dist/aos.css';

const functionalities = [
  "others.functionalities.1",
  "others.functionalities.2",
  "others.functionalities.4",
  "others.functionalities.6",
  "others.functionalities.7",
];

const benefits = [
  "others.benefits.1",
  "others.benefits.2",
  "others.benefits.3",
];

const ForOthers = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col w-full lg:px-24 lg:py-16 mx-auto lg:h-min-screen text-text/80 overflow-x-hidden">
      <div className="h-full w-full md:p-8 flex lg:flex-row flex-col-reverse rounded-2xl">
        <Trans>
          <div className="flex flex-col md:border bg-[#FFFBF6] p-8 rounded-3xl lg:w-1/2 font-bold text-4xl text-title"
               data-aos="fade-right" data-aos-anchor-placement="top-center"
               data-aos-easing="ease-in-sine" data-aos-duration="600">
            <p>{t("others.title")}</p>
            <p className="text-xl">{t("others.subtitle")}</p>

            <Tabs tabs={[
              { title: t("foragronomist.functionalities.title"), items: functionalities },
              { title: t("foragronomist.benefits.title"), items: benefits },
            ]} />
          </div>
        </Trans>
        <div className="lg:w-1/2 lg:h-auto h-[520px] flex lg:justify-start justify-center lg:pl-10 lg:pb-20 lg:bg-[length:500px] bg-[length:400px] bg-no-repeat bg-center"
             data-aos="fade-left" data-aos-anchor-placement="top-center"
             data-aos-easing="ease-in-sine" data-aos-duration="600"
             style={{ backgroundImage: `url(${ImgOthers})` }}>
        </div>
      </div>
    </div>
  );
};

export default ForOthers;
