import React from "react";
import { useTranslation } from "react-i18next";
import Cover from "../imgs/bg-pages.png";

import ImgBg from "../img/sunset.png";
import ImgBgMission from "../img/mission.png";

const Vision = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto  flex flex-col gap-8 rounded-2xl overflow-hidden text-text bg-[#FFFBF6]">
      <div
        className="w-full bg-cover bg-green-950 h-[500px] top-0 relative flex items-center px-8 "
        style={{ backgroundImage: `url(${Cover})` }}
      >
        <h2 className="text-5xl font-bold text-white mx-auto flex max-w-7xl w-full">
          {t("nav.1.6")}
        </h2>
      </div>
      <div className="mx-auto flex flex-col max-w-7xl w-full pt-24">
        <div className="flex flex-col lg:flex-row gap-8">
          <div
            className="w-full lg:w-1/2 bg-cover bg-no-repeat rounded-xl lg:bg-center-top bg-top-center h-64 lg:h-auto"
            style={{ backgroundImage: `url(${ImgBg})` }}
          ></div>
          <div className="flex flex-col justify-center p-8 lg:w-1/2">
            <h2 className="text-4xl font-bold text-title">
              {t("whatisit.vision.title")}
            </h2>
            <p className="text-xl text-title font-medium text-pretty mt-4">
              {t("whatisit.vision.1")}
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row-reverse gap-8">
          <div
            className="w-full lg:w-1/2 bg-cover bg-no-repeat rounded-xl lg:bg-center-top bg-top-center h-64 lg:h-auto"
            style={{ backgroundImage: `url(${ImgBgMission})` }}
          ></div>
          <div className="flex flex-col justify-center p-8 lg:w-1/2">
            <h2 className="text-4xl font-bold text-title">
              {t("whatisit.mission.title")}
            </h2>
            <p className="text-xl text-title font-medium text-pretty mt-4">
              {t("whatisit.mission.1")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
