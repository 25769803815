import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import ImgFarmer from "../imgs/farmer.png";
import Tabs from "./ui/tabs";
import Leafs from "../svg/leafs.svg";

import AOS from 'aos';
import 'aos/dist/aos.css';

const functionalities = [
  "forfarmer.functionalities.1",
  "forfarmer.functionalities.2",
  "forfarmer.functionalities.3",
  "forfarmer.functionalities.4",
  "forfarmer.functionalities.5",
];

const benefits = [
  "forfarmer.benefits.1",
  "forfarmer.benefits.2",
  "forfarmer.benefits.3",
];

const ForFarmer = () => {
  const { t } = useTranslation();
  
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col lg:px-4 xl:px-24 lg:py-16 w-full mx-auto lg:h-min-screen text-text/80 overflow-x-hidden">
      <div className="h-full w-full md:p-8 flex lg:flex-row flex-col-reverse rounded-2xl justify-between">
        <img src={Leafs} alt="Leafs" className="absolute left-8 mt-24 md:-mt-24 opacity-80" />
        <Trans>
          <div className="flex flex-col md:border bg-[#FFFBF6] p-8 rounded-3xl lg:w-1/2 font-bold text-4xl text-title" 
               data-aos="fade-right" 
               data-aos-anchor-placement="top-center" 
               data-aos-easing="ease-in-sine">
            {t("forfarmer.title")}
            <Tabs 
              tabs={[
                { title: t("foragronomist.functionalities.title"), items: functionalities },
                { title: t("foragronomist.benefits.title"), items: benefits },
              ]}
            />
          </div>
        </Trans>
        <div className="lg:w-1/2 lg:h-auto h-[520px] flex lg:justify-start justify-center lg:pl-10 lg:pb-20 lg:bg-[length:500px] bg-[length:400px] bg-no-repeat bg-center" 
             data-aos="fade-left" 
             data-aos-anchor-placement="top-center" 
             data-aos-easing="ease-in-sine" 
             data-aos-duration="600"
             style={{ backgroundImage: `url(${ImgFarmer})` }}>
        </div>
      </div>
    </div>
  );
};

export default ForFarmer;
