import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Cover from "../imgs/bg-pages.png";

const content = [
  { section: "1" },
  { section: "2" },
  { section: "3" },
  { section: "4" },
  { section: "5" },
  { section: "6" },
  { section: "7" },
  { section: "8" },
  { section: "9" },
  { section: "10" },
  { section: "11" },
  { section: "12" },
  { section: "13" },
  { section: "14" },
  { section: "15" },
  { section: "16" },
  { section: "17" },
  { section: "18" },
  { section: "19" },
  { section: "20" },
];

export const TermsUse = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#FFFBF6]  gap-24 h-full justify-center w-full items-center  text-title ">
      <div
        className="w-full bg-cover bg-green-950 h-[500px] top-0 relative flex items-center px-8 "
        style={{ backgroundImage: `url(${Cover})` }}
      >
        <h2 className="text-5xl font-bold text-white mx-auto flex max-w-7xl w-full">
          {t("termsOfUse.title")}
        </h2>
      </div>

      <p className=" mx-auto gap-8 max-w-7xl w-full p-8">
        {t("termsOfUse.lastUpdated")}
      </p>
      <div className="py-24  flex flex-col mx-auto gap-8 max-w-7xl w-full px-8">
        {content.map((item, index) => (
          <div key={index}>
            <h2 className="text-3xl font-bold pb-4">
              {t(`termsOfUse.sections.${item.section}.title`)}
            </h2>
            <Trans
              components={{
                br: <br />,
              }}
            >
              <p className="text-lg pb-16">
                {t(`termsOfUse.sections.${item.section}.content`)}
              </p>
            </Trans>
          </div>
        ))}
      </div>
    </div>
  );
};
