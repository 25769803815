import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
// import Img1 from "../img/agrokowledge.png";
// import Img2 from "../img/data.png";
// import Img3 from "../img/actions.png";
// import Img4 from "../img/Collaboration.png";

import ImgTimeline from "../imgs/mobile-timeline.png";
import ImgTimelineW from "../imgs/web-actions.png";


import AOS from "aos";
import "aos/dist/aos.css";

import Button from "./ui/moreButton";

const content = [
  { title: "whyCamvio.1.title" },
  { title: "whyCamvio.2.title" },
  { title: "whyCamvio.3.title" },
  { title: "whyCamvio.4.title" },
  { title: "whyCamvio.5.title" },
];

const Solves = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className=" w-auto lg:mr-32 flex lg:flex-row flex-col gap-8 md:p-8 rounded-2xl text-title">
      <div className="flex  lg:w-1/2 " data-aos="fade-right">
        <div className="flex flex-col ">
          <img
            src={ImgTimeline}
            alt="CamvioMobile"
            className="absolute w-1/4 lg:hidden flex z-10 pb-10 -right-0 mt-10"
          />
          <img
            src={ImgTimelineW}
            alt="CamvioWeb"
            className=" w-full lg:hidden flex  "
          />
        </div>

        <img
          src={ImgTimelineW}
          alt="CamvioWeb"
          className=" absolute xl:w-[900px] md:-[900px] -left-24 hidden lg:flex"
        />
        <img
          src={ImgTimeline}
          alt="CamvioMobile"
          className=" absolute xl:w-[280px] lg:w-[140px]  mt-16 xl:ml-[450px]  lg:ml-[250px] hidden lg:flex"
        />
      </div>
      <div
        className="flex  md:border bg-[#FFFBF6] p-8 flex-col h-auto md::w-1/2 gap-4 rounded-xl"
        data-aos="fade-left"
        data-aos-anchor-placement="top-center"
        data-aos-easing="ease-in-sine"
      >
        <Trans>
          <h2 className="text-4xl font-bold  tracking-wide">
            {t("whyCamvio.title")}
          </h2>
          <div className="flex flex-col subpixel-antialiased py-4 font-medium gap-6  h-full ">
            {content.map(({ title }, index) => (
              <ul key={index} className=" text-2xl list-disc  pl-6  leading-9">
                <li className="text-pretty   rounded-2xl  ">
                  <Trans
                    i18nKey={title}
                    components={{
                      1: <br />,
                      strong: <strong className="" />,
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
         <Button to="/how-it-works"/>
        </Trans>
      </div>

      {/* <div className="overflow w-1/2">
    <div className="absolute  2xl:right-20 xl:-right-24 lg:-right-24 -mt-2 hidden lg:flex lg:w-[600px]  xl:w-[800px]" data-aos="fade-left"   data-aos-duration="400">
      <img src={webImage} alt="webCamvio" className=" "/>
    </div>
    <div className="absolute 2xl:right-24 xl:-right-8 lg:right-2 xl:mt-10 lg:mt-32 right-0 mt-16 hidden lg:flex lg:w-[200px] 2xl:w-[250px]" data-aos="fade-left"    data-aos-delay="500">
      <img src={mobImage} alt="webCamvio" className="  "/>
    </div>
    </div> */}
    </div>
  );
};

export default Solves;
