// Button.js
import React from 'react';
import { Link } from 'react-router-dom'; 
import { useTranslation } from "react-i18next";
import { FaArrowRightLong } from "react-icons/fa6";

const Button = ({ to }) => {
    const { t } = useTranslation();
  return (
    <Link to={to}>
      <button className="px-4 py-2 flex items-center gap-4 font-bold  hover:bg-green-700 hover:text-white rounded-lg hover:shadow text-green-700">
   {t("cta.learnmore")}
   <FaArrowRightLong />
      </button>
    </Link>
  );
};

export default Button;
