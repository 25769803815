import React, { useEffect} from "react";
import Hero from "../Components/Hero";
import WhatIs from "../Components/WhatIs";
import Solves from "../Components/Solves";

import ForFarmer from "../Components/ForFarmer";
import ForAgronomist from "../Components/ForAgronomist";
import ForOthers from "../Components/ForOthers";
import ForWho from "../Components/ForWho";

import Solutions from "../Components/Solutions";
import BlogPosts from "../Components/BlogNews";








const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const [activeSection, setActiveSection] = useState("Hero");
  // const sectionRefs = useRef({});
  // const scrollContainerRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (!scrollContainerRef.current) return;

  //     const scrollContainer = scrollContainerRef.current;
  //     const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight / 2;

     

  //     for (const section of sections) {
  //       const ref = sectionRefs.current[section];
  //       if (ref) {
  //         const offsetTop = ref.offsetTop - scrollContainer.offsetTop;
  //         const offsetHeight = ref.offsetHeight;

  //         if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
  //           setActiveSection(section);
            
  //           break;
  //         }
  //       }
  //     }

     
  //   };

  //   const scrollContainer = scrollContainerRef.current;
  //   scrollContainer.addEventListener("scroll", handleScroll);

  //   return () => {
  //     scrollContainer.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleNavigation = (section) => {
  //   if (sectionRefs.current[section]) {
  //     sectionRefs.current[section].scrollIntoView({ behavior: "smooth", block: "start" });
  //     setActiveSection(section);
  //   }
  // };

  return (

<div className="flex  flex-col gap-20  bg-[#FFFBF6] overflow-x-hidden">
    <Hero/>
   
    <WhatIs/>
   
    <Solutions/>
    <Solves/>
 
    <ForFarmer/>
    <ForAgronomist/>
    <ForOthers/>
    <ForWho/>
   <BlogPosts/>

   
  
    </div>
    // <div
    //   // ref={scrollContainerRef}
    //   className=" overflow-y-scroll block  w-full mx-auto  h-screen bg-amber-50/50 no-scrollbar md:pt-32 pt-0 "
      
    // >
     
    //     <div className="fixed lg:top-1/2 bottom-3 pr-8 left-5 transform -translate-y-1/2 z-50 space-y-2">
    //       {sections.map((section) => (
    //         <button
    //           key={section}
    //           onClick={() => handleNavigation(section)}
    //           className={`block w-3 h-3 rounded-full ${
    //             activeSection === section ? "bg-blue-300" : "bg-white shadow"
    //           }`}
    //         ></button>
    //       ))}
    //     </div>
     

    //   <div className="scroll-smooth relative">
    //     {sections.map((section, index) => {
    //       const SectionComponent = components[section];
    //       return (
    //         <div
    //           key={section}
    //           ref={(el) => (sectionRefs.current[section] = el)}
    //           className={`lg:snap-center snap-none  lg:py-28 py-8 p-2 ${index === 3 ? "bg-orange-400/0" : index ===2 ? "bg-slate-200/20" : index === 5 ?"bg-slate-200/30": index===7? "bg-slate-200/30":""}`}
    //         >
    //           <SectionComponent />
    //         </div>
    //       );
    //     })}
    //   </div>
    //   <div className="lg:snap-end snap-none">
    //     <Footer />
    //   </div>
    // </div>
  );
};

export default Homepage;
