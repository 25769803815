import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cover from "../imgs/bg-pages.png";

export const HowItWorks = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#FFFBF6]  gap-24 fh-full justify-center w-full items-center  text-title ">
      <div
        className="w-full bg-cover bg-green-950 h-[500px] top-0 relative flex items-center px-8 "
        style={{ backgroundImage: `url(${Cover})` }}
      >
        <h2 className="text-5xl font-bold text-white mx-auto flex max-w-7xl w-full">
          {t("howitworks.title")}
        </h2>
      </div>

      <div className="py-24 gap-24  flex flex-col ">
        <div className=" flex flex-col gap-4 mx-auto max-w-7xl px-8">
          <h2 className="text-3xl font-bold">{t("howitworks.1")}</h2>
          <p className="text-lg">{t("howitworks.1.sub")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.1.1")}</h3>
          <p>{t("howitworks.1.1.1")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.1.2")}</h3>
          <p>{t("howitworks.1.2.1")}</p>
        </div>

        <div className=" flex flex-col gap-4 mx-auto max-w-7xl px-8">
          <h2 className="text-3xl font-bold">{t("howitworks.2")}</h2>
          <p className="text-lg">{t("howitworks.2.sub")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.2.1")}</h3>
          <p>{t("howitworks.2.1.1")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.2.2")}</h3>
          <p>{t("howitworks.2.2.1")}</p>
        </div>

        <div className=" flex flex-col gap-4 mx-auto max-w-7xl px-8">
          <h2 className="text-3xl font-bold">{t("howitworks.3")}</h2>
          <p className="text-lg">{t("howitworks.3.sub")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.3.1")}</h3>
          <p>{t("howitworks.3.1.1")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.3.2")}</h3>
          <p>{t("howitworks.3.2.1")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.3.3")}</h3>
          <p>{t("howitworks.3.3.1")}</p>
        </div>

        <div className=" flex flex-col gap-4 mx-auto max-w-7xl px-8">
          <h2 className="text-3xl font-bold">{t("howitworks.4")}</h2>
          <p className="text-lg">{t("howitworks.3.sub")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.4.1")}</h3>
          <p>{t("howitworks.4.1.1")}</p>

          <h3 className="font-bold text-xl">{t("howitworks.4.2")}</h3>
          <p>{t("howitworks.4.2.1")}</p>
        </div>

        <div className=" flex flex-col gap-4 mx-auto max-w-7xl px-8">
          <h2 className="text-3xl font-bold">{t("howitworks.5")}</h2>
          <p className="text-lg">{t("howitworks.5.sub")}</p>

          <ul className="list-disc list-outside px-8">
            <li>{t("howitworks.5.1")}</li>
            <li>{t("howitworks.5.2")}</li>
            <li>{t("howitworks.5.3")}</li>
            <li>{t("howitworks.5.4")}</li>
            <li>{t("howitworks.5.5")}</li>
            <li>{t("howitworks.5.6")}</li>
            <li>{t("howitworks.5.7")}</li>
            <li>{t("howitworks.5.8")}</li>
            <li>{t("howitworks.5.9")}</li>
            <li>{t("howitworks.5.10")}</li>
            <li>{t("howitworks.5.11")}</li>
            <li>{t("howitworks.5.12")}</li>
            <li>{t("howitworks.5.13")}</li>
            <li>{t("howitworks.5.14")}</li>
            <li>{t("howitworks.5.15")}</li>
            <li>{t("howitworks.5.16")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
