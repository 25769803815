import React, { useEffect, useState } from "react";
import axios from "axios";
import Leafs from "../svg/leafs.svg";
import { useTranslation } from "react-i18next";
import { FaArrowRightLong } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";

const BlogPosts = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `https://blog.camvio.gr/wp-json/wp/v2/posts`
        );
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error); // Log the full error
        setError(error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [language]); // Fetch posts when language changes

  // Update language when localStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const newLanguage = localStorage.getItem("i18nextLng") || "en";
      setLanguage(newLanguage);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  if (loading) {
    return <p className="text-center">Loading...</p>;
  }

  if (error) {
    return (
      <p className="text-center text-red-500">
        Error fetching posts: {error.message}
      </p>
    );
  }

  return (
    <div className="flex flex-col w-full lg:px-4 xl:px-24  lg:py-16 mx-auto lg:h-min-screen text-title  overflow-x-hidden p-8">
      <div className="h-full  md:px-16 px-0 flex flex-col w-full rounded-2xl gap-16">
        <h2 className="text-4xl font-bold  tracking-wide mb-4">
          {" "}
          {t("blog.title")}
        </h2>
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          {posts
            .filter((post) => post.lang === language) // Replace desiredLanguage with the language you want to filter by
            .slice(0, 3) // Show only the first 3 posts
            .map((post) => (
              <a
                href={post.link}
                key={post.id}
                className="border rounded-lg hover:shadow-md p-4"
              >
                {post.rttpg_featured_image_url.full[0] && (
                  <div
                    key={post.id}
                    className="w-full h-64 bg-cover bg-center mb-4 rounded"
                    style={{
                      backgroundImage: `url(${post.rttpg_featured_image_url.full[0]})`,
                    }}
                  ></div>
                )}
                <h3 className="text-2xl font-semibold mb-2">
                  {post.title.rendered}
                </h3>
              </a>
            ))}
          <a
            href="https://blog.camvio.gr/blog"
            className="border rounded-lg hover:shadow-md p-4 flex flex-col justify-between"
          >
            <div className="w-full flex items-end">
              <img
                src={Leafs}
                alt="Leafs"
                className=" w-2/3 opacity-80 place-content-end "
              />
            </div>
            <button className="px-4 py-2 flex items-center gap-4 font-bold   rounded-lg  text-green-700">
              {t("blog.cta")}
              <FaArrowRightLong />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlogPosts;
