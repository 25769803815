import React, {useEffect} from "react";
import Agronomist from "../Images/agronomist01.png";
import Farmer from "../Images/farmer01.png";
import Teams from "../Images/teams.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Platforms = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <div className="flex flex-col w-full lg:px-24 lg:py-16 mx-auto lg:h-min-screen text-text/80 l ">
      <div className="h-full  p-8 flex flex-col w-full rounded-2xl gap-16">
        <div className="   flex  text-4xl font-bold text-left w-full text-title ">
          {t("get.title")}
        </div>

        <div className=" flex md:flex-row flex-col w-full grid-cols-1 my-10 gap-4 text-title">
          <div className=" text p-8 content-center bg-[#FFFBF6] flex flex-col w-full justify-between  lg:w-1/3  rounded-xl   border" data-aos="fade-right" >
            <img src={Farmer} alt="Content"></img>
            <div className="title text-2xl w-full font-bold text-center  py-8">
              {t("cta.farmer")}
            </div>

            <Link
              to="https://play.google.com/store/apps/details?id=gr.dataverse.camvio.camvio_mobile_app&pcampaignid=web_share"
              className="text-center ml-3 font-bold border-2 border-green-700 text-green-700  hover:bg-green-700 hover:text-white px-4 py-3 rounded-2xl"
            >
              {t("cta.ctafarmer")}
            </Link>
          </div>

          <div className=" text p-8 content-center bg-[#FFFBF6] flex flex-col w-full justify-between  lg:w-1/3 rounded-xl  border" data-aos="fade-up" >
            <img src={Agronomist} alt="Content"></img>
            <div className="title text-2xl   w-full font-bold text-center  py-8">
              {t("cta.agronomist")}
            </div>

            <Link
              to="https://app.camvio.gr/"
              className="text-center ml-3 font-bold border-2 border-green-700 text-green-700  hover:bg-green-700 hover:text-white px-4 py-3 rounded-2xl"
            >
              {t("cta.ctaagronomist")}
            </Link>
          </div>

          <div className=" animate-on-scroll text p-8 bg-[#FFFBF6] content-center flex flex-col w-full lg:w-1/3 justify-between   rounded-xl   border" data-aos="fade-left">
            <img src={Teams} alt="Content"></img>
            <div className="title text-2xl w-full   font-bold text-center py-8 ">
              {t("cta.others")}
            </div>

            <a
              href="mailto:support@camvio.gr"
              className="text-center ml-3 font-bold border-2 border-green-700 text-green-700  hover:bg-green-700 hover:text-white px-4 py-3 rounded-2xl"
            >
              {t("cta.ctaothers")}
            </a>
          </div>
        </div>
        {/* <div className=" mt-12 mx-9  w-fit h-full text-center bg-green text-2xl text-white px-6 py-2 rounded-2xl shadow"> {t("get.subtitle")}</div> */}
      </div>
    </div>
  );
};

export default Platforms;
