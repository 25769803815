import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './i18n/en.json';
import translationGR from './i18n/gr.json';

// Define your resource files
const resources = {
  en: {
    translation: translationEN
  },
  gr: {
    translation: translationGR
  }
};

// Initialize i18next with language detector
i18n
  .use(LanguageDetector)  // Use the LanguageDetector
  .use(initReactI18next)  // Passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'gr',  // Fallback language
    detection: {
      order: ['localStorage', 'querystring', 'navigator'],
      lookupLocalStorage: 'i18nextLng',  // Key in localStorage
      caches: ['localStorage']  // Specify where to cache the language
    },
    interpolation: {
      escapeValue: false  // React already does escaping
    }
  });

export default i18n;
