import React, { useEffect } from "react";
import ImgBG from "../img/BG.png";

import { useTranslation, Trans } from "react-i18next";
import ImgHero from "../img/herohero.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="hero w-full h-[110vh] bg-[#FFFBF6] bg-no-repeat  bg-left-bottom  bg-contain mt-16 lg:mt-0 items-center overflow-x-hidden"
      style={{ backgroundImage: `url(${ImgBG})` }}
    >
      <div className="   flex lg:flex-row flex-col   md:p-24 p-6 items-start ">
        <div className="w-full flex flex-col justify-center xl:pt-20 md:pt-8">
        <div className="welcome  md:pt-8 md:text-6xl tracking-tighter lg:tracking-normal text-5xl font-bold pr-4  text-title  ">
          <Trans components={{ 1: <br /> }}>{t("hero.headline")} </Trans>
        </div>
        <div className="s md:pt-4 pt-2 md:text-3xl text-xl font-medium text-subtext ">
          <Trans components={{ 1: <br /> }}>{t("hero.subhead")}</Trans>
        </div>
        </div>
        <div
          className="w-full flex h-full md:justify-end justify-center xl:pr-60    lg:pt-10 xl:pt-24"
          data-aos="fade-left"
          data-aos-duration="800"
        >
          <img src={ImgHero} alt="Camvio" className="md:w-3/7 mt-8 "></img>
        </div>
      </div>
    </div>
  );
};

export default Hero;
