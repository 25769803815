import React, {useEffect} from "react";
import { useTranslation, Trans } from "react-i18next";
import ImgAgronomist from "../imgs/agronomist.png";
import Tabs from "./ui/tabs";

import AOS from 'aos';
import 'aos/dist/aos.css';

const functionalities = [
  "foragronomist.functionalities.1",
  "foragronomist.functionalities.2",

  "foragronomist.functionalities.4",
  "foragronomist.functionalities.5",
];

const benefits = [
  "foragronomist.benefits.1",
  "foragronomist.benefits.2",
  "foragronomist.benefits.3",
];

const ForAgronomist = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="flex flex-col w-full lg:px-4 xl:px-24  lg:py-16 mx-auto lg:h-min-screen text-text/80  overflow-x-hidden ">
      
      <div className="h-full  md:p-8 flex lg:flex-row flex-col w-full rounded-2xl ">
      <div className="lg:w-1/2 lg:h-auto h-[520px] flex lg:justify-start justify-center lg:pl-10 lg:pb-20 lg:bg-[length:500px] bg-[length:400px] bg-no-repeat bg-center  " 
     data-aos="fade-left" 
     data-aos-anchor-placement="top-center" 
     data-aos-easing="ease-in-sine" 
     data-aos-duration="600"
     style={{ backgroundImage: `url(${ImgAgronomist})` }}>
</div>

        <Trans>
        <div className="  md:border  bg-[#FFFBF6]  p-8 rounded-3xl flex flex-col md:w-1/2 font-bold text-4xl  text-title" data-aos="fade-right" data-aos-anchor-placement="top-center" data-aos-easing="ease-in-sine" >
          {t("foragronomist.title")}
          {/* <div className="photos flex flex-col items-center justify-center h-full">
            <img src={ImgFarmer} alt="farmer" />
          </div> */}

          <Tabs tabs={[
            { title: t("foragronomist.functionalities.title"), items: functionalities },
            { title: t("foragronomist.benefits.title"), items: benefits },
          ]} />
        </div>
        </Trans>
    
      </div>
    </div>
  );
};

export default ForAgronomist;
